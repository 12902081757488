import { useState, useEffect } from 'react';

function App() {
    const [data, setData] = useState(null)

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/todos/1')
            .then((res) => res.json())
            .then((data) => {
                setData(data)
            })
    }, [])

    return (
        <div>
            <h1>{JSON.stringify(data)}</h1>
        </div>
    );
}

export default App;
